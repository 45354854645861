.combobox {
  position: relative;

  .combo-dropdown {
    position: absolute;
    width: 100%;
    z-index: 1000;
  }

  .combo-dropdown,
  .combo-wrap {
    @include themify {
      border: 1px solid themed('combobox-border');
    }
  }

  .arrow {
    position: absolute;
    top: 6px;
    right: 6px;
    pointer-events: none;

    @include themify {
      fill: themed('combobox-border');
    }
  }

  .clear {
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;

    @include themify {
      fill: themed('combobox-border');
    }
  }

  .filter {
    border: 0;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;

    @include themify {
      border: 1px solid themed('combobox-border');
    }

    &:focus {
      outline: 0;
    }
  }

  .items {
    overflow: hidden;
    list-style: none;
    cursor: default;
    margin: 0;
    padding: 0;

    li {
      padding-left: 10px;
      white-space: nowrap;
      transition: background-color 0.1s ease, color 0.1s ease;

      @include themify {
        border-bottom: 1px solid themed('combobox-item-border');
      }

      &:nth-child(odd) {
        @include themify {
          background-color: themed('combobox-item-odd-bg');
        }
      }

      &:nth-child(even) {
        @include themify {
          background-color: themed('combobox-item-even-bg');
        }
      }

      &:hover,
      &.active {
        @include themify {
          color: themed('combobox-item-hover-color');
          background-color: themed('combobox-item-hover-bg');
        }
      }
    }

    .custom-control-label {
      width: 100%;
    }
  }
}
