.auction-filter {
  position: relative;

  .form-label {
    font-weight: bold;

    span {
      font-weight: normal;
    }

    @include themify {
      color: themed('auction-label');
    }
  }

  .column-select {
    min-width: 220px;
    max-width: 220px;
  }

  .column-medium {
    min-width: 180px;
    max-width: 180px;
  }

  .column-narrow {
    min-width: 130px;
    max-width: 130px;
  }

  .column-range {
    max-width: 360px;
    flex-grow: 3;

    .form-group {
      margin-bottom: 0.65rem;
    }
  }

  .filter-form {
    padding: 1rem 1rem 0;
  }
}

.auction-filter-bottom {
  margin-bottom: 1rem;

  .total-found {
    text-transform: uppercase;
    font-weight: bold;
  }

  .buttons {
    text-align: right;
  }

  .btn {
    min-width: 120px;
    margin-left: 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }
}

.list-photos {
  width: 74px;

  img {
    display: block;
    width: 100%;
    height: 50px;
    margin-bottom: .25rem;
  }
}
