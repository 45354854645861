$c-1: #207ece;
$c-2: #2699fb;
$c-3: #f1f9ff;
$c-bright: #ffffff;
$c-text: #212529;
$c-muted: #6c757d;
$c-success: #00a896;
$c-info: #17a2b8;
$c-warning: #e4ba4e;
$c-danger: #f15946;

$theme-default: (
    colors: (
        primary: $c-1,
        secondary: $c-2,
        success: $c-success,
        info: $c-info,
        warning: $c-warning,
        danger: $c-danger,
    ),

    auction-lot-visited: $c-3,
    auction-label: $c-1,

    auth-title-color: $c-1,

    body-bg: $c-bright,
    body-color: $c-text,

    card-active-border: $c-1,

    checkbox-border: darken($c-3, 20%),

    combobox-border: $c-1,
    combobox-item-odd-bg: $c-3,
    combobox-item-even-bg: $c-bright,
    combobox-item-border: darken($c-3, 20%),
    combobox-item-hover-color: $c-bright,
    combobox-item-hover-bg: $c-1,

    icon-star-active: $c-warning,
    icon-star-inactive: $c-muted,

    navbar-bg: $c-1,
    navbar-color: $c-bright,

    notify-primary-bg: $c-text,
    notify-primary-color: #ffffff,
    notify-success-bg: $c-success,
    notify-success-color: #ffffff,
    notify-info-bg: $c-info,
    notify-info-color: #ffffff,
    notify-warning-bg: $c-warning,
    notify-warning-color: #ffffff,
    notify-error-bg: $c-danger,
    notify-error-color: #ffffff,

    form-label: $c-text,
    form-input-border: $c-1,
    form-mini-bg: $c-3,
    form-mini-color: $c-1,

    range-slider-rail: $c-muted,
    range-slider-track: $c-2,
    range-slider-handle: $c-2,
    range-slider-handle-border: $c-bright,

    scrollbar-thumb-color: transparentize($c-1, 0.35),

    sidebar-bg: $c-2,
    sidebar-bg-light: lighten($c-2, 10%),
    sidebar-color: $c-bright,

    status-active: $c-1,
    status-success: $c-success,
    status-warning: $c-warning,
    status-fail: $c-danger,
    status-other: $c-muted,

    tab-border: $c-1,
    tab-bg: $c-3,
    tab-item-color: $c-text,
    tab-item-bg: $c-bright,
    tab-item-border: #808080,
    tab-item-hover-bg: $c-3,
    tab-item-active-color: $c-1,
    tab-item-disabled-color: $c-muted,

    table-header-bg: #f5f6fa,
    table-label-selected: $c-1,
    table-border: #dee2e6,
    table-bg-darker: $c-3,
);
