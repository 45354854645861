.alert-rounded {
  border-radius: 24px;
  text-align: center;
}

.alert-full {
  border-radius: 0;
  text-align: center;
}

.alertbox-full {
  margin-top: -1rem;
}

.alert-primary {
  @include themify {
    background-color: themed('notify-primary-bg');
    color: themed('notify-primary-color');

    .close {
      color: themed('notify-primary-color');
    }
  }
}

.alert-success {
  @include themify {
    background-color: themed('notify-success-bg');
    color: themed('notify-success-color');
  }
}

.alert-info {
  @include themify {
    background-color: themed('notify-info-bg');
    color: themed('notify-info-color');
  }
}

.alert-warning {
  @include themify {
    background-color: themed('notify-warning-bg');
    color: themed('notify-warning-color');
  }
}

.alert-danger {
  @include themify {
    background-color: themed('notify-error-bg');
    color: themed('notify-error-color');
  }
}
