.spoiler {
  position: relative;
  margin-bottom: 1rem;

  .arrow {
    position: absolute;
    top: 8px;
    right: 8px;

    @include themify {
      fill: themed('combobox-border');
    }
  }

  h5 {
    cursor: pointer;
    padding: .5rem;

    @include themify {
      background-color: themed('table-header-bg');
    }
  }
}
