.table-common {
  @include table-common-header;

  > :not(:first-child) {
    border-top: 1px;
  }
}

.table-control {
  margin-bottom: 1rem;
}

.card > .table {
  margin-bottom: 0;
}

.data-table {
  @include table-common-header;

  .sort {
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-right: 12px;

    &:before,
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      right: 0;
    }

    &:before {
      top: 5px;
    }

    &:after {
      top: 12px;
    }

    &.asc,
    &.desc {
      @include themify {
        color: themed('table-label-selected');
      }
    }

    &.none:before,
    &.desc:before {
      border-top: 0;
      border-right: 4px solid transparent;
      border-bottom: 5px solid;
      border-left: 4px solid transparent;
    }

    &.none:hover:before,
    &.asc:before {
      @include themify {
        border-bottom-color: themed('table-label-selected');
      }
    }

    &.none:after,
    &.asc:after {
      border-top: 5px solid;
      border-right: 4px solid transparent;
      border-bottom: 0;
      border-left: 4px solid transparent;
    }

    &.none:hover:after,
    &.desc:after {
      @include themify {
        border-top-color: themed('table-label-selected');
      }
    }
  }

  > :not(:first-child) {
    border-top: 1px;
  }
}

.table-nav {
  display: flex;
  justify-content: space-between;

  .pagination {
    margin-bottom: 0;
  }

  .limit {
    display: flex;

    span {
      white-space: nowrap;
      padding: 5px 0 0 10px;
    }
  }
}

.col-actions {
  text-align: right;
  padding-right: 14px !important;
}

.actions {
  a[class*="btn-outline"]:first-child,
  button[class*="btn-outline"]:first-child {
    border-right-width: 0;
    min-width: 100px;
  }

  .danger {
    &:hover {
      color: #ffffff;

      @include themify {
        background-color: map-get(themed('colors'), 'danger');
      }
    }
  }
}

td.min {
  width: 1%;
  white-space: nowrap;
}

#table-car-tax {
  td:not(.engine) {
    text-align: right;
    cursor: pointer;

    .highlight {
      @include themify {
        color: map-get(themed('colors'), 'secondary');
      }
    }
  }

  td:not(.engine):hover {
    background: #dcdcdc;
  }
}

.table-sticky-header {
  thead {
    position: sticky;
    top: 48px;
    z-index: 1;
  }
}
