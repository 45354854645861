.star,
.star-bright {
  cursor: pointer;
}

.star {
  @include themify {
    fill: themed('icon-star-inactive');
  }
}

.star-bright {
  @include themify {
    fill: themed('icon-star-active');
  }
}

.icon-help {
  width: 1rem;
  height: 1rem;

  &.help {
    @include themify {
      fill: themed('colors', 'primary');
    }
  }
  &.warning {
    @include themify {
      fill: themed('colors', 'danger');
    }
  }
}
