.card-shipping {
  .ports {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .agent {
      text-align: center;
    }

    .arrow {
      position: relative;

      &:after {
        content: "";
        border: 6px solid transparent;
        border-left-color: #343a40;
        position: absolute;
        top: -6px;
        left: -3px;
      }
    }

    .arrival {
      text-align: right;
    }
  }
}

.table-purchase-form {
  input[type="number"] {
    text-align: right;
  }

  td:last-child {
    text-align: right;
  }
}

.table-purchase-card {
  td {
    text-align: right;
  }

  td:first-child {
    text-align: left;
  }
}

.table-product-refund {
  td:nth-child(2) {
    text-align: right;
  }

  td:nth-child(3) {
    white-space: nowrap;
  }
}

.shipments {
  .left {
    padding: 1rem;
    @include themify {
      border-right: 1px solid themed('colors', 'primary');
    }

    tr.active {
      @include themify {
        background-color: themed('tab-bg');
      }
    }
  }

  .right {
    padding: 1rem;
    &.empty {
      @include themify {
        background-color: themed('tab-bg');
      }
    }
  }
}

.product-progress {
  width: 100px;
  margin-top: 4px;
}

.products-mobile {
  img {
    height: 60px;
  }

  .product-progress {
    width: 100%;
  }
}

.product-photos {
  display: flex;

  div {
    position: relative;

    img {
      width: 95%;
    }

    svg {
      position: absolute;
      top: 40%;
      left: 40%;
      color: white;
      display: none;
    }

    &:hover {
      svg {
        display: inline-block;
      }
    }
  }
}
