.shipping-agents {
  .contact {
    margin-bottom: 0.25rem;
    border-left: 4px solid;
    padding-left: 0.5rem;

    @include themify {
      border-left-color: themed('colors', 'primary');
    }
  }
}
