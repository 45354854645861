@charset "UTF-8";

// 0. Themes
@import
  'themes/default',
  'themes/night';

// 1. Configuration and helpers
@import
  'utils/variables',
  'utils/functions',
  'utils/mixins',
  'utils/helpers';

// 2. Vendors
@import 'vendors/bootstrap_customizer';
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '~react-datepicker/dist/react-datepicker.css';

// 3. Base stuff
@import
  'base/base',
  'base/common';

// 4. Layout-related sections
@import
  'layout/navbar',
  'layout/sidebar';

// 5. Components
@import
  'components/alert',
  'components/buttons',
  'components/carousel',
  'components/chat',
  'components/checkbox-list',
  'components/combobox',
  'components/custom-scrollbar',
  'components/datepicker',
  'components/dropzone',
  'components/editable',
  'components/forms',
  'components/interrupt-modal',
  'components/invoice-blank',
  'components/logs-beautiful',
  'components/modal',
  'components/product-timeline.scss',
  'components/range-slider',
  'components/scroll-hider',
  'components/spinner',
  'components/spoiler',
  'components/icons',
  'components/image-gallery',
  'components/table',
  'components/table-lots',
  'components/tabs',
  'components/toast';

// 6. Page-specific styles
@import
  'pages/auction-bids',
  'pages/auction-filter',
  'pages/auction-lot',
  'pages/company-forms',
  'pages/user-form',
  'pages/errors',
  'pages/home',
  'pages/notifications',
  'pages/products',
  'pages/shipping-agents',
  'pages/auth';
