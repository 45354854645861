.user-types {
  display: flex;
  justify-content: center;

  .mini-card {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin: 0 0 1rem;
    padding: .5rem;
    width: 140px;
    text-align: center;
    cursor: pointer;

    .title {
      font-weight: bold;
      font-size: 1.125rem;
      margin-bottom: .25rem;;
    }

    .subtitle {
      font-size: .875rem;
    }

    &.active {
      @include themify {
        border-color: themed('card-active-border');
      }
    }
  }
}

.is-desktop .user-types .mini-card {
  margin: 0 .5rem 1rem;
}

.permissions-toggle {
  text-align: center;
  margin-bottom: 1rem;
}

.permissions-table {
  th:nth-child(n+2),
  td:nth-child(n+2){
    text-align: center;
  }
}

.plan-table {
  td:last-child {
    text-align: right;
  }
}

.user-balance {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
}
