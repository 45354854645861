.sidebar {
  will-change: visibility;
  visibility: hidden;
  width: $sidebar-width;
  transform: translateX( -$sidebar-width );
  transition: transform .25s cubic-bezier(0.4,0.0,0.2,1), visibility 0s linear .25s;
  height: calc(100vh - #{$navbar-height});
  position: fixed;
  top: $navbar-height;

  @include themify {
    background: themed('sidebar-bg');
  }

  &.open {
    transform: translateX(0);
    visibility: visible;
    box-shadow: 0 0 16px rgba(0,0,0,.28);
    transition: transform .25s cubic-bezier(0.4,0.0,0.2,1), visibility 0s linear 0s;
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  a {
    display: block;
    text-decoration: none;

    @include themify {
      color: themed('sidebar-color');
    }
  }

  svg {
    margin: 12px;

    @include themify {
      fill: themed('sidebar-color');
    }
  }

  .no-icon {
    padding: 12px 0 12px 48px;
    display: inline-block;
  }

  .opener {
    @include themify {
      background-color: themed('sidebar-bg-light');
    }
  }

  .item {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    white-space: nowrap;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    & > a {
      flex-grow: 1;
    }
  }
}

.nav-top {
  text-transform: uppercase;
  position: relative;

  ul {
    @include themify {
      background: themed('sidebar-bg');
    }

    a {
      padding-right: 12px;
    }
  }

  &.desktop {
    ul {
      display: none;
      position: absolute;
      top: 0;
      left: calc(100% + 8px);
      min-width: 160px;
      border-radius: 4px;

      &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 140%;
        left: -140%;
        z-index: -1;
      }
    }

    li {
      position: relative;
    }

    li.hover > ul {
      display: block;
    }
  }
}

.nav-bottom {
  bottom: 0;
  position: absolute;
  width: 100%;
  text-transform: uppercase;
}

.sidebar-backdrop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
}

@media only screen and (min-width: 1680px) {
  .menu-main {
    opacity: 0;
  }

  .sidebar-backdrop {
    display: none;
  }

  .sidebar.open {
    box-shadow: none;
  }
}

.languages {
  text-align: center;

  button {
    padding: 4px 8px;
    margin: 0 4px;
    font-size: .85rem;
    text-decoration: none;

    @include themify {
      color: themed('sidebar-color');
    }

    &.active {
      font-weight: bold;
    }
  }
}
