.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px;
  width: 30px;
}

.image-gallery-using-mouse:not(:hover) {
  .image-gallery-icon {
    color: transparent;
  }
}
