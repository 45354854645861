@mixin themify {
  @each $theme, $map in $themes {
    $theme-map: map-get($themes, $theme) !global;
    .theme-#{$theme} & {
      @content;
    }
  }
}

@mixin table-common-header {
  thead {
    font-size: 0.875rem;

    @include themify {
      background-color: themed('table-header-bg');
    }

    th {
      border: 0;
      font-weight: normal;
      font-style: italic;
      vertical-align: middle;
    }
  }
}
