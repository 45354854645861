.auth-form {
  height: 100vh;
  display: flex;

  h1 {
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;

    @include themify {
      color: themed('auth-title-color');
    }
  }

  .subtitle {
    text-align: center;
    margin-bottom: 2rem;
  }

  form {
    width: 100%;
    max-width: 390px;
    padding: 2rem 1rem;
    margin: auto;

    .dirty {
      position: relative;

      .alert {
        position: absolute;
        top: -80px;
        width: 100%;
      }
    }

    .form-label {
      font-weight: bold;
    }

    .form-control {
      border-radius: 10px;
      border-width: 2px;
    }

    .btn {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1rem;
    }
  }
}
