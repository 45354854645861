.interrupt-modal {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  display: flex;
  align-items: center;
  z-index: 100;
  text-align: center;

  .inner {
    margin: auto;
    text-align: left;
    padding: 40px 24px;
    box-shadow: 0 20px 40px transparentize($secondary, 0.8);
  }
}
