@each $color, $value in map-get($theme-default, 'colors') {
  .btn-#{$color} {
    @include themify {
      $tmp-color: map-get(themed('colors'), $color);
      @include button-variant($tmp-color, $tmp-color, #ffffff, $hover-color: #ffffff);
    }
  }

  .btn-outline-#{$color} {
    @include themify {
      @include button-outline-variant(map-get(themed('colors'), $color), #ffffff);
    }
  }
}

a[class*="btn-outline"],
button[class*="btn-outline"] {
  border-width: 2px;
}

.btn-icon {
  svg {
    margin-top: -4px;
    fill: currentColor;
  }
}

.btn-sm:not(.btn-link) {
  svg {
    width: 1rem;
    height: 1rem;
  }
}

.btn-more {
  padding: 0 .25rem;
  cursor: pointer;
}

.button-icon {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
