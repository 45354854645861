.logs-beautiful {
  .divider {
    color: #6c757d;
    overflow: hidden;
    text-align: center;

    &:before,
    &:after {
      background-color: #6c757d;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    &:before {
      right: 0.5em;
      margin-left: -50%;
    }

    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }

  .log {
    padding: 0 .5rem 0 .25rem;

    &:hover {
      background-color: #eeeeee;
    }
  }

  .executor {
    float: right;
  }
}
