.dropzone {
  border: 2px #6c757d dashed;
  margin-bottom: 1rem;
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;

  &.invalid {
    border-color: $c-danger;

    .title{
      color: $c-danger;
    }
  }
}

.drop-image {
  .title {
    color: #6c757d;
    font-weight: bold;

    svg {
      width: 5rem;
      height: 5rem;
    }

    img {
      max-width: 100%;
      height: 5rem;
    }
  }

  .drop-here {
    padding: 2.5rem 0.5rem;
    background-color: #ebebeb;
  }
}

.drop-wrap {
  position: relative;

  .delete {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.25rem 0.25rem 0.5rem 0.5rem;
  }

  &.disabled {
    .dropzone {
      cursor: auto;
    }

    .title {
      color: #a5abb2;
    }
  }
}
