.custom-scrollbar {
  > div:nth-child(2) {
    height: 4px !important;

    > div {
      @include themify {
        background-color: themed('scrollbar-thumb-color') !important;
      }
    }
  }

  > div:nth-child(3){
    width: 4px !important;

    > div {
      @include themify {
        background-color: themed('scrollbar-thumb-color') !important;
      }
    }
  }
}
