.chat-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .attachments {
    .attachment {
      position: relative;
      display: inline-block;
      width: 100px;
      aspect-ratio: 1;
      max-width: 31%;
      margin-right: 4px;

      button {
        position: absolute;
        top: 0;
        right: 0;
      }

      .no-preview {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: .25rem;
      }
    }
  }
}

.chat-messages {
  overflow: auto;
  margin-bottom: .5rem;

  .message {
    padding: .25rem .5rem;
    background-color: #f7f7f7;
    border-radius: 8px;
    position: relative;

    .header {
      display: flex;
      justify-content: space-between;
    }

    &:before {
      content: "";
      width: 0;
      height: 0;
      top: 0;
      position: absolute;
      border-style: solid;

    }

    &.their {
      margin: 0 3rem 1rem 1rem;

      &:before {
        left: -8px;
        border-width: 16px 0 0 16px;
        border-color: whitesmoke transparent transparent transparent;
      }
    }

    &.mine {
      margin: 0 1rem 1rem 3rem;
      text-align: right;

      &:before {
        right: -8px;
        border-width: 16px 16px 0 0;
        border-color: whitesmoke transparent transparent transparent;
      }
    }

    &.m-unread {
      border-right: 3px solid $c-success;
    }

    a {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .divider {
    text-align: center;
    font-size: .75rem;
  }
}

.chat-sender {
  .attachments {
    margin-bottom: .5rem;
  }
}

.chat-drag {
  textarea {
    border-color: #e4ba4e !important;
  }
}

.chat-list {
  --bs-list-group-bg: transparent;

  .on-hover {
    display: none;
  }

  .list-group-item:hover .on-hover {
    display: inline-block;
    margin-bottom: -6px;
  }

  .on-hover.dropdown {
    margin: -4px 8px -10px 0;

    .dropdown-toggle:after {
      display: none;
    }
  }
}

.chat-wrapper {
  height: calc(100vh - 150px);
}
