.notification {
  padding: .25rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  &:last-child {
    border-bottom: none;
  }
}

.unread {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    top: 8px;
    left: -10px;

    @include themify {
      background-color: themed('colors', 'success');
    }
  }
}

.notification .unread:before {
  left: -12px;
}

.wide-dropdown .dropdown-menu {
  min-width: 25rem;
}
