.bid-groups {
  width: auto;

  td, th {
    padding: 0.3rem  1rem;
  }

  button {
    svg {
      fill: currentColor;
      height: 19px;
    }

    &:hover svg {
      fill: #ffffff;
    }
  }
}

* {
  .status-active,
  .status-success,
  .status-warning,
  .status-fail,
  .status-other {
    text-transform: uppercase;
  }

  @include themify {
    .status-active {
      color: themed('status-active');
      font-weight: bold;
    }

    .status-success {
      color: themed('status-success');
      font-weight: bold;
    }

    .status-warning {
      color: themed('status-warning');
    }

    .status-fail {
      color: themed('status-fail');
    }

    .status-other {
      color: themed('status-other');
    }
  }
}

.bid-alarm {
  display: inline-block;
  padding-top: .25rem;
  text-align: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;

  @include themify {
    &.auto {
      background-color: themed('colors', 'danger')
    }

    &.manual {
      background-color:  themed('status-other');
    }
  }
}
