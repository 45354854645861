.editable {
  cursor: pointer;

  .btn-act {
    padding: 0 .25rem;

    &:hover {
      color: #ffffff !important;
      border-radius: 4px;

      @include themify {
        background-color: themed("colors", "primary");
      }
    }
  }

  svg {
    width: 1rem;
    height: 1rem;
  }

  &.active {
    display: flex;

    &:not(.wide) {
      max-width: 168px;
    }
  }

  @media print {
    margin-right: 0;

    .btn-act {
      display: none;
    }
  }
}

.editable-input-group {
  display: inline-block;
  position: relative;

  .prefill {
    position: absolute;
    top: 0;
    right: -30px;
  }
}
