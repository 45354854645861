.range-slider {
  margin: 0 5%;
  position: relative;
  height: 40px;

  .rail {
    position: absolute;
    width: 100%;
    height: 14px;
    cursor: pointer;

    @include themify {
      background-color: themed('range-slider-rail');
    }
  }

  .handle {
    position: absolute;
    margin-left: -11px;
    margin-top: -6px;
    z-index: 2;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);

    @include themify {
      background-color: themed('range-slider-handle');
      border: 2px solid themed('range-slider-handle-border');
    }
  }

  .track {
    position: absolute;
    height: 14px;
    z-index: 1;
    border-radius: 7px;
    cursor: pointer;

    @include themify {
      background-color: themed('range-slider-track');
    }
  }

  &.disabled {
    .handle {
      background-color: #666;
    }
    .track {
      background-color: #999;
    }
  }

  &.compact {
    margin: 0 2%;
    height: 20px;

    .rail,
    .track {
      height: 10px;
    }

    .handle {
      margin-left: -8px;
      margin-top: -4px;
      width: 16px;
      height: 16px;
    }
  }

  .tick {
    position: absolute;
    margin-top: 22px;
    font-size: 10px;
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: -8px;
      width: 1px;
      height: 5px;
      background-color: #C8C8C8;
    }
  }

  .fake-tooltip {
    position: absolute;
    z-index: 1070;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem;
    white-space: nowrap;
    font-size: .875rem;
    width: 70px;
    margin-left: -35px;
    margin-top: -45px;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -.4rem;
      margin-left: -.4rem;
      border-style: solid;
      border-width: .4rem .4rem 0;
      border-color: transparent;
      border-top-color: #000;
    }
  }
}
