.form-mini {
  padding: 1rem;
  margin-bottom: 1.5rem;

  @include themify {
    background-color: themed('form-mini-bg');
  }

  .form-text {
    @include themify {
      color: themed('form-mini-color');
    }
  }
}

.form-label {
  @include themify {
    color: themed('form-label');
  }
}

.form-control:not(.is-invalid):not(.is-valid) {
  @include themify {
    border-color: themed('form-input-border');
  }
}

.form-select {
  @include themify {
    border-color: themed('form-input-border');
  }
}

.form-sent {
  background: #ebebeb;
  position: relative;
  
  &:before {
    content: "";
    border-radius: 50%;
    background: #343a40;
    width: 48px;
    height: 48px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -24px 0 0 -24px;
  }

  &:after {
    content: "";
    width: 25px;
    height: 13px;
    border: 3px solid #fff;
    border-top-width: 0;
    border-right-width: 0;
    transform: rotate(-45deg);
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -11px 0 0 -12px;
  }
}
