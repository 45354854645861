.modal-confirm {
  .question {
    text-align: center;
    margin-bottom: 2rem;
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    .btn {
      width: 120px;
    }
  }
}

@for $i from 1 through 6 {
  $zIndexBackdrop:  #{1050 + (5 * $i)};
  $zIndexContent:  #{1050 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}
