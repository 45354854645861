.checkbox-list,
.radio-list {
  overflow-y: auto;
  margin-left: -3px;
  padding-left: 3px;
  list-style: none;
}

.checkbox-list,
.radio-list,
.combobox {
  .form-check {
    margin-bottom: 0;
  }

  .form-check-label {
    width: 100%;
  }
}

.form-check-input {
  @include themify {
    border-color: themed('checkbox-border');
  }
}
