.nav-tabs {
  @include themify {
    border-bottom-color: themed('tab-border');
  }

  .nav-item {
    &:nth-child(n+2) {
      margin-left: -20px;

      .nav-link {
        padding-left: 2rem;
      }
    }

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        z-index: #{11 - $i};
      }
    }
  }

  .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 1.25rem;
    font-weight: bold;

    @include themify {
      color: themed('tab-item-color');
      border-color: themed('tab-item-border') themed('tab-item-border') themed('tab-border');
      background-color: themed('tab-item-bg');
    }

    &:hover,
    &:focus {
      outline: 0;

      @include themify {
        background-color: themed('tab-item-hover-bg');
      }
    }

    &.active {
      @include themify {
        color: themed('tab-item-active-color');
        background-color: themed('tab-bg');
        border-color: themed('tab-border') themed('tab-border') themed('tab-bg');
      }
    }

    &.disabled {
      @include themify {
        color: themed('tab-item-disabled-color');
      }
    }
  }
}

.block-accent {
  border: 1px solid;
  margin-bottom: 1rem;

  @include themify {
    background-color: themed('tab-bg');
    border-color: themed('tab-border');
  }
}

.tab-pane .block-accent {
  border-top-width: 0;
}
