@keyframes spinner-spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner-container {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1040;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #fff;
  animation: spinner-spin 0.8s linear infinite;
}
