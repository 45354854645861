.navbar {
  @include themify {
    background-color: themed('navbar-bg');
    color: themed('navbar-color');
  }
}

.navbar-brand {
  text-transform: uppercase;
  font-weight: bold;

  a {
    text-decoration: none;

    @include themify {
      color: themed('navbar-color');
    }
  }
}

.navbar-icon {
  border-radius: 50%;
  margin: 0 10px;
  display: flex;
  padding: 8px;

  .nav-link {
    padding: 0;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:focus {
    outline: none;
  }

  svg {
    @include themify {
      fill: themed('navbar-color');
    }
  }

  .dropdown-toggle:after {
    content: none
  }

  .dropdown-item {
    svg {
      @include themify {
        fill: themed('body-color');
      }
    }

    &:active {
      svg {
        @include themify {
          fill: themed('navbar-color');
        }
      }
    }
  }

  .icon-with-count {
    position: relative;

    .badge {
      position: absolute;
      top: -6px;
      left: 10px;
    }
  }
}

.breadcrumb {
  padding: .75rem 1rem;
  background-color: #e9ecef;
}
