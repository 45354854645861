.invoice {
  .logo {
    width: 100%;
  }

  .stamp {
    width: 150px;
  }

  .small-table {
    td:last-child {
      text-align: right;
    }
  }

  .products {
    margin-bottom: 0;
  }

  .due-big {
    display: flex;
    font-size: 18px;
    padding: 0 .3rem;
    font-weight: bold;

    div {
      padding: .5rem;
      text-align: center;
      border: 1px solid #212529;

      &:first-child {
        background-color: #212529;
        color: #ffffff;
        flex-grow: 1;
      }

      &:last-child {
        flex-grow: 2;
      }
    }
  }

  .stamp-paid,
  .stamp-deleted {
    display: inline-block;
    padding: 0 1.5rem;
    position: absolute;
    top: 0;
    right: 50px;
    font-size: 36px;
    text-transform: uppercase;
    font-weight: bold;
    transform: rotate(-25deg);
    pointer-events: none;
  }

  .stamp-paid {
    border: 4px solid blue;
    color: blue;
  }

  .stamp-deleted {
    border: 4px solid red;
    color: red;
  }

  @media print {
    border: none;

    .btn {
      display: none;
    }
  }

  @media only screen and (max-width: 420px) {
    font-size: 40%;

    .stamp {
      width: 75px;
    }

    .stamp-paid,
    .stamp-deleted {
      font-size: 21px;
    }
  }
}

.services .custom-checkbox {
  margin-top: 5px;
}
