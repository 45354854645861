.lot-info {
  td:first-child {
    width: 50%;
    font-weight: bold;
  }
}

.lot-table-mini {
  td:nth-child(odd) {
    @include themify {
      background: themed('table-bg-darker');
    }
  }

  td:nth-child(2) {
    width: 160px;
  }
}

.get-photos {
  position: absolute;
  top: 0;
  right: 1rem;
}
