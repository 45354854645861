.main-pages {
  .item {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;

    img {

    }
  }
}
