$theme-night: (
    colors: (
        primary: black,
        secondary: gray,
        success: green,
        info: cyan,
        warning: yellow,
        danger: red,
    ),
  // TODO copy & paste from default then modify
);
