.text-accent {
  @include themify {
    color: themed("colors", "primary");
  }
}

.show-more {
  cursor: pointer;
}

.container-narrow {
  max-width: 640px;
}

.blink {
  animation: 1.5s ease-out blink-anim;
}

@keyframes blink-anim {
  from {
    background-color: transparent;
  }

  10% {
    background-color: #666666;
  }

  25% {
    background-color: #666666;
  }

  50% {
    background-color: #C8C8C8;
  }
}
