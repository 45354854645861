.error-page {
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .panel {
    width: 100%;
    max-width: 390px;
    padding: 0 15px;
    margin: auto;
    text-align: center;
  }

  .number {
    font-size: 10rem;
    line-height: 10rem;
    font-weight: bold;

    @include themify {
      color: themed('colors', 'secondary');
    }
  }

  .title {
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;

    @include themify {
      color: themed('colors', 'secondary');
    }
  }
}

[lang="ru"] .error-page .title {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
