.scrolled-down {
  top: -100px !important;
}

.scrolled {
  transition: top 0.3s ease-in-out;
}

.btn-floating {
  position: fixed;
  top: 60px;
  right: .75rem;
  z-index: 999; // TODO 1030 after profile dropdown removed
}
