.toast-container {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1150;
}

.toast {
  min-width: 200px;
  animation: .25s ease-out noticeMe;

  .close {
    text-shadow: none;
  }
}

@keyframes noticeMe {
  from {
    margin-left: 100%;
  }

  80% {
    margin-left: -20%;
  }

  to {
    margin-left: 0;
  }
}

.t-primary {
  @include themify {
    background-color: themed('notify-primary-bg');
    color: themed('notify-primary-color');

    .close {
      color: themed('notify-primary-color');
    }
  }

}

.t-success {
  @include themify {
    background-color: themed('notify-success-bg');
    color: themed('notify-success-color');
  }
}

.t-info {
  @include themify {
    background-color: themed('notify-info-bg');
    color: themed('notify-info-color');
  }
}

.t-warning {
  @include themify {
    background-color: themed('notify-warning-bg');
    color: themed('notify-warning-color');
  }
}

.t-danger {
  @include themify {
    background-color: themed('notify-error-bg');
    color: themed('notify-error-color');
  }
}
