html {
  height: 100vh;
}

body {
  min-height: 100vh;
}

#root {
  @include themify {
    color: themed('body-color');
  }
}

.full-page {
  padding: 2rem 0;
}

.is-desktop main {
  padding: $navbar-height 0 32px;
}

@media only screen and (min-width: 1680px) {
  .is-desktop main {
    margin-left: $sidebar-width;
  }
}

.is-mobile main {
  padding: calc(#{$navbar-height} + 1rem) 0 2rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
