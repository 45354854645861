.product-timeline {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: stretch;

  li {
    text-align: center;
    width: 500px;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      width: 50%;
      border-top: 2px solid #dcdcdc;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    &.active:after {
      border-color: #207ece;
    }
  }

  .active + .active:before {
    border-color: #207ece;
  }

  .date {
    padding-top: .5rem;
  }

  .title {
    &:before {
      content: '';
      position: absolute;
      top: -.2rem;
      left: 50%;
      border-radius: 50%;
      width: .5rem;
      height: .5rem;
      background-color: #dcdcdc;
      transform: translateX(-50%);
    }
  }

  .active .title:before {
    background-color: #207ece;
  }

  span {
    display: block;
  }
}
