.table-lots {
  .col-photos {
    width: 2%;
    white-space: nowrap;

    img {
      margin-right: 4px;
      cursor: pointer;
      height: 50px;

      &:first-child {
        width: 35px;
      }

      &:only-child {
        width: 80px;
      }
    }
  }

  .col-photos-2 {
    width: 152px;

    img {
      margin-right: 4px;
    }
  }

  .chassis-zoomed {
    width: 220px;
    height: 50px;
  }

  .col-date {
    white-space: nowrap;
  }

  .col-grade {
    max-width: 170px;
  }

  .col-number {
    text-align: right;
  }

  .btn {
    text-transform: uppercase;
    font-weight: bold;
  }

  .visited {
    @include themify {
      background-color: themed('auction-lot-visited');
    }
  }
}
