.carousel-control-prev:hover,
.carousel-control-next:hover {
  background: rgba(0, 0, 0, 0.15);
}

.image-gallery-content {
  &.left,
  &.right {
    .image-gallery-slide .image-gallery-image {
      max-height: calc(100vh - 160px); // fix for jumping page in single lot
    }
  }
}

.modal-body {
  .image-gallery-content {
    &.left,
    &.right {
      .image-gallery-slide .image-gallery-image {
        max-height: 90vh !important;
        min-height: 80vh !important;
      }
    }
  }
}
